<script lang="ts" setup>
import { useBasketStore } from '~/stores/basket'

const navbar = ref(null)
const showDrawer = useState<boolean>('navbar.showDrawer', () => false)
const showOptions = useState<boolean>('navbar.showOptions', () => false)
const showCart = useState<boolean>('navbar.showCart', () => false)

const basketStore = useBasketStore()

// methods
const updateDrawerOptions = () => {
  if (showDrawer.value || showOptions.value || showCart.value) {
    document.body.classList.add('overflow-hidden')
  } else {
    document.body.classList.remove('overflow-hidden')
  }
}

let resizeTimeout: number | null = null

const handleResize = () => {
  if (resizeTimeout !== null) {
    clearTimeout(resizeTimeout)
  }

  resizeTimeout = setTimeout(() => {
    const minW = 1024
    if (window.innerWidth < minW) {
      updateDrawerOptions()
    }
    resizeTimeout = null
  }, 100) as unknown as number
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})

const toggleCart = () => {
  showCart.value = !showCart.value
}
const toggleDrawer = () => (showDrawer.value = !showDrawer.value)
const toggleOptions = (show?: boolean) => {
  if (show) {
    showOptions.value = show
  } else {
    showOptions.value = !showOptions.value
  }
}
</script>

<template>
  <div ref="navbar" class="top-5 z-40 fixed left-0 right-0 lg:hidden">
    <div id="navbar-banner" class="banner">
      <slot name="banner" />
    </div>
    <div class="container mx-auto px-4">
      <div
        class="rounded-btn border-1 border-light-gray backdrop-blur"
        style="background: #ebeae466"
      >
        <div class="py-5 px-6">
          <div class="relative flex items-center">
            <!-- title -->
            <slot name="title">
              <NuxtLink
                tag="a"
                class="mr-3 ease-in-out duration-150 hover:opacity-50"
                :to="{ name: 'index' }"
                aria-label="Read more about Seminole tax hike"
              >
                <nuxt-img
                  provider="s3"
                  src="/img_logo.svg"
                  sizes="(max-width: 640px) 50vw, 25vw"
                  width="164"
                  height="44"
                  alt="Domaci Piscanci Logo"
                />
              </NuxtLink>
            </slot>
            <!-- menu -->
            <slot name="menu" />
            <!-- options:toggle -->
            <div
              v-if="$slots['options']"
              class="flex-1 flex justify-end lg:hidden"
            >
              <button
                class="flex items-center focus:outline-none relative"
                aria-label="Toggle Shopping Cart"
                buttonName="cart-button"
                @click="toggleCart()"
              >
                <span
                  class="flex ease-in-out duration-150 hover:bg-[#45994526] rounded-btn px-4 py-2 items-center text-green text-2xl"
                  aria-hidden="true"
                >
                  <font-awesome-icon :icon="['fas', 'shopping-cart']" />
                  <span v-if="basketStore.totalItems > 0" class="badge">
                    {{ basketStore.totalItems }}
                  </span>
                </span>
              </button>
              <button
                buttonName="menu-button"
                class="flex items-center focus:outline-none"
                aria-label="Toggle Options Menu"
                @click="toggleOptions()"
              >
                <span
                  class="flex ease-in-out duration-150 hover:bg-[#45994526] rounded-btn px-4 py-2 items-center text-green text-2xl"
                  aria-hidden="true"
                >
                  <font-awesome-icon :icon="['fas', 'bars']" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ClientOnly>
      <Teleport to="#app-after">
        <!-- drawer -->
        <Transition name="slide-fade-from-up" mode="out-in">
          <div
            v-if="showDrawer && $slots['drawer']"
            class="fixed lg:hidden bg-gray-100 dark:bg-slate-800 pt-12 top-0 left-0 w-screen h-screen z-30 flex flex-col"
          >
            <div class="flex-1 flex flex-col relative overflow-y-auto">
              <slot name="drawer" :toggle-drawer="toggleDrawer" />
            </div>
          </div>
        </Transition>
        <!-- shopping cart -->
        <Transition name="slide-fade-from-up" mode="out-in">
          <div v-if="showCart && $slots['cart']" class="">
            <div class="flex-1 flex flex-col relative overflow-y-auto">
              <slot name="cart" :toggle-cart="toggleCart" />
            </div>
          </div>
        </Transition>

        <!-- options -->
        <div v-if="showOptions && $slots['options']">
          <slot
            name="options"
            :toggle-options="toggleOptions"
            :show-options="showOptions"
          />
        </div>
      </Teleport>
    </ClientOnly>
  </div>
</template>
<style scoped>
.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 0.75rem;
  line-height: 1;
}
</style>
