<script setup lang="ts">
import { useStepperStore } from '@/stores/stepper'
import { useBasketStore } from '@/stores/basket'

const stepperStore = useStepperStore()
const basketStore = useBasketStore()

const goToNextStep = () => {
  stepperStore.next()
}
</script>
<template>
  <BuilderSidebar>
    <template #options="{ toggleOptions }">
      <ActionSheet @on-close="toggleOptions(false)">
        <ActionSheetBody>
          <ActionSheetHeader text="Menu" />
          <div class="mt-2">
            <DashboardSidebar />
          </div>
          <div class="mt-6 text-sm font-bold capitalize">
            {{ $t('components.language_switcher.change_language') }}
          </div>
          <div class="mt-2">
            <LanguageSwitcher type="select-box" />
          </div>
        </ActionSheetBody>
        <Button
          text="Close"
          type="secondary"
          class="btn-base none-green !bg-white"
          button-name="menu-button"
          @click.prevent="toggleOptions(false)"
        />
      </ActionSheet>
    </template>
    <template #drawer>
      <slot name="drawer" />
    </template>
    <template #cart="{ toggleCart }">
      <ActionSheet @on-close="toggleCart">
        <ActionSheetBody class="basket-content-container">
          <ActionSheetHeader text="Košarica" />
          <div class="mt-2">
            <ShoppingBasket />
          </div>
        </ActionSheetBody>
        <Button
          v-if="stepperStore.currentStep === 1 && !basketStore.isBasketEmpty()"
          class="btn-base fill-green hover:no-underline ease-in-out duration-150 hover:bg-[#45994526] rounded-btn font-bold"
          @click="goToNextStep"
          @click.prevent="toggleCart"
        >
          Nadaljuj na vnos podatkov
        </Button>
        <Button
          text="Zapri"
          type="secondary"
          class="btn-base none-green !bg-white"
          button-name="cart-button"
          @click.prevent="toggleCart"
        />
      </ActionSheet>
    </template>
  </BuilderSidebar>
</template>
<style>
.basket-content-container {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
