<script lang="ts">
import { AppConfigInput } from 'nuxt/schema'
import { useUserStore } from '@/stores/user'

export interface TopMenuItem {
  isAuth: string
  text: string
  icon?: any
  route?: any
}

export interface BottomMenuItem {
  text: string
  icon?: any
  route?: any
}
export default defineComponent({
  props: {
    mode: {
      type: String,
      default: 'normal',
    },
  },
  setup() {
    const sidebar = ref(null)
    const route = useRoute()
    const userStore = useUserStore()
    const isAuthenticated = computed(() => userStore.isUserLoggedIn)
    const { t } = useLang()
    const app = useAppConfig() as AppConfigInput
    const topMenu = computed((): TopMenuItem[] => [
      {
        isAuth: 'both',
        text: t('menu.order'),
        icon: 'cart-shopping',
        route: 'dashboard',
      },
      {
        isAuth: 'false',
        text: t('menu.login'),
        icon: 'user',
        route: 'dashboard-login',
      },
      {
        isAuth: 'false',
        text: t('menu.register'),
        icon: 'user-pen',
        route: 'dashboard-registration',
      },
      {
        isAuth: 'true',
        text: t('menu.myProfile'),
        icon: 'address-card',
        route: 'dashboard-user',
      },
      {
        isAuth: 'true',
        text: t('menu.logout'),
        icon: 'right-from-bracket',
        route: 'dashboard-logout',
      },
    ])
    const bottomMenu = computed((): BottomMenuItem[] => [
      {
        text: t('menu.webpage'),
        icon: 'globe',
        route: 'index',
      },
      {
        text: t('menu.contact'),
        icon: 'phone',
        route: 'dashboard-contact',
      },
      {
        text: t('menu.aboutUs'),
        icon: 'circle-info',
        route: 'about-us',
      },
      {
        text: t('menu.terms'),
        icon: 'file-lines',
        route: 'dashboard-terms-conditions',
      },
    ])
    return {
      isAuthenticated,
      sidebar,
      route,
      topMenu,
      bottomMenu,
    }
  },
})
</script>

<template #menu>
  <div
    ref="sidebar"
    class="lg:fixed lg:top-0 lg:w-80 lg:p-7 lg:h-screen lg:flex lg:flex-col justify-between overflow-y-auto"
  >
    <div class="">
      <div>
        <NuxtLink
          tag="a"
          class="ease-in-out duration-150 hover:opacity-50 mb-8 block hidden lg:block"
          :to="{ name: 'index' }"
        >
          <nuxt-img
            provider="s3"
            src="/img_logo.svg"
            sizes="(max-width: 640px) 50vw, 25vw"
            width="164"
            height="44"
            alt="Domaci Piscanci Logo"
          />
        </NuxtLink>
      </div>

      <div
        class="font-bold text-medium-gray text-sm uppercase tracking-widest mb-3"
      >
        Naročila
      </div>
      <div class="flex-1 overflow-y-auto">
        <ul>
          <li v-for="(item, i) in topMenu" :key="i">
            <Anchor
              v-if="
                item.isAuth === 'both' ||
                (item.isAuth === 'true' && isAuthenticated) ||
                (item.isAuth === 'false' && !isAuthenticated)
              "
              :to="{ name: item.route }"
              class="hover:no-underline"
            >
              <div
                class="menu"
                :class="{
                  active: route.name === item.route,
                }"
              >
                <font-awesome-icon :icon="['fas', item.icon]" class="mr-3" />
                {{ item.text }}
              </div>
            </Anchor>
          </li>
        </ul>
      </div>
    </div>

    <div class="mt-8">
      <div>
        <ul>
          <li v-for="(item, i) in bottomMenu" :key="i">
            <Anchor :to="{ name: item.route }" class="hover:no-underline">
              <div
                class="menu"
                :class="{
                  active: route.name === item.route,
                }"
              >
                <font-awesome-icon :icon="['fas', item.icon]" class="mr-3" />
                {{ item.text }}
              </div>
            </Anchor>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
