<template>
  <div>
    <slot name="app-before" />
    <div id="app-before"></div>
    <slot name="header">
      <DashboardNavbar>
        <template #drawer>
          <DashboardSidebar mode="mobile" />
        </template>
      </DashboardNavbar>
    </slot>
    <div class="flex">
      <div class="flex-none w-80 hidden lg:flex">
        <DashboardSidebar />
      </div>
      <div class="flex-1 w-full pt-7">
        <slot />
      </div>
    </div>
    <slot name="app-after" />
    <div id="app-after"></div>
  </div>
</template>

<script setup></script>
